import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Components from "./components.js"
import PlikKZ from "./PlikKZ"
import { animateScroll as scroll } from "react-scroll"

const Wypelniacz = styled.div`
  position: relative;
  display: blok;
  > h4 {
    margin-top: 1rem;
    border-bottom: 1px solid #aaa;
  }
`

const Pobieralnia = props => {
  const [state, setState] = useState("Różne")
  const [dane, setDane] = useState([])
  useEffect(() => {
    fetch(`https://cdn.tayama.pl/system/pliki.json`)
      .then(response => response.json())
      .then(resultData => {
        setDane(resultData)
      })
  }, [])

  function wybor (x) {
    setState(x)
    scroll.scrollToBottom()
  }
  var parzyste = true

  return (
    <SbEditable content={props.blok}>
      <Wypelniacz className={props.blok.klasa}>
        {props.blok.kolumny &&
          props.blok.kolumny.map(blok => {
            return React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              funkcja: wybor,
            })
          })}
        <h4>{state}</h4>
        {/* {console.log(dane)} */}
        <div>
          {dane.map((item, i) => {
            if (item.nazwa === state) {
              return item.pliki.map((plik, j) => {
                parzyste = !parzyste
                return (
                  <PlikKZ
                    key={j}
                    parzyste={parzyste}
                    tytul={plik.tytul}
                    link={plik.link}
                  ></PlikKZ>
                )
              })
            } else return ""
          })}
        </div>
      </Wypelniacz>
    </SbEditable>
  )
}

export default Pobieralnia
