import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import ScrollUpButton from "react-scroll-up-button"
import { style } from "../utils/Index"
import Navbar from "./Navbar"

const Layout = ({ children }) => (
  <div>
    <GlobalStyle />
    <ScrollUpButton
      style={{
        width: 56,
        height: 56,
        padding: 12,
        outline: "none",
        border: "1px solid #aaa",
        borderRadius: "50%",
        borderColor: style.kolory.tayamaSzary,
        backgroundColor: style.kolory.tayamaSzary,
      }}
      ToggledStyle={{ right: 20, bottom: 40 }}
    />
    <Navbar />
    {children}
  </div>
)

const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  /* scroll-behavior: smooth; */
  max-width: 1280px;
  margin: 0 auto;
  background-color: $light-gray;
}
body {
  font-family: 'PT Sans', sans-serif;
}
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
