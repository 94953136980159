import React from "react"
import SbEditable from "storyblok-react"
import Components from "./components.js"
const LinkDiv = props => {
  return (
    <SbEditable content={props.blok}>
      <a href={props.blok.link} alt={props.blok.alt}>
        {props.blok.tresc &&
          props.blok.tresc.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </a>
    </SbEditable>
  )
}

export default LinkDiv
