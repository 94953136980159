import React, { useState } from "react"
import Modal from "react-modal"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const Newsletter = props => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalWasOpen, setWasOpen] = useState(true)
  // const [modalWasOpen, setWasOpen] = useState(
  //   document.cookie.split("; ").find(row => row.startsWith("mailing-popup")) !==
  //     undefined
  // )

  function openModal () {
    setIsOpen(true)
    // document.cookie = "mailing-popup=false; max-age=15552000"
  }
  function closeModal () {
    setIsOpen(false)
  }
  function handleNewsletter () {
    setTimeout(() => {
      openModal()
    }, 60000)
  }
  if (!modalWasOpen) {
    setWasOpen(true)
    // handleNewsletter()
  }

  return (
    <React.Fragment>
      <iframe
        title='newsletter'
        id='fm-fc-f-ane6mpmlcz'
        src='https://forms.freshmail.io/f/hx8u94z7gp/ane6mpmlcz/index.html'
        frameBorder='0'
        marginHeight='0'
        marginWidth='0'
        width='98%'
        height='358px'
      ></iframe>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Zapis do newslettera'
      >
        {/* <div id='fm-pu-container'> */}
        <iframe
          title='newsletter'
          id='fm-fc-f-ane6mpmlcz'
          src='https://forms.freshmail.io/f/hx8u94z7gp/ane6mpmlcz/index.html'
          frameBorder='0'
          marginHeight='0'
          marginWidth='0'
          width='98%'
          height='358px'
        ></iframe>
        {/* </div> */}
        <button onClick={closeModal}>Nie, dziękuję</button>
      </Modal>
    </React.Fragment>
  )
}

export default Newsletter
