import React, { useState } from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import StoryblokClient from "storyblok-js-client"
import { FaAngleRight } from "react-icons/fa"
import { resize } from "./helpers/ImageServices"
import { style } from "../utils/Index"

const Wypelniacz = styled.section`
  .pudelko {
    position: relative;
    display: blok;
  }
  .oferta {
    display: block;
    display: inline-block;
    margin: 0;
    color: white;
    &:focus {
      outline-style: none;
      outline-color: transparent;
    }
    border: 1px solid white;
    box-shadow: 0.5rem 0.5rem 0.5rem gray;
  }
  .nieaktualne {
    border-color: white;
    background-color: ${style.kolory.tayamaSzary};
    position: relative;
    top: auto;
    .dosklepu {
      display: none;
    }
  }
  .aktualne {
    border-color: ${style.kolory.tayamaZielony};
    background-color: ${style.kolory.tayamaZielony};
    position: relative;
    top: -15%;
    .dosklepu {
      display: inline-block;
    }
    @media screen and (min-width: 960px) {
      top: auto;
    }
  }
  .basic-card {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
  }
  .basic-card-image {
    grid-row-start: 1;
    grid-row-end: 2;
    img {
      width: 100%;
    }
    border-bottom: 1px solid white;
  }
  .basic-card-content {
    display: none;
    border: 0;
    margin: 0;
    padding: 5px;
    text-align: center;
    @media screen and (min-width: 960px) {
      display: block;
    }
  }
  .focia {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    @media screen and (min-width: 960px) {
      width: 90%;
    }
  }
  .miniaturki {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 2%;
    position: relative;
    left: 10%;
    bottom: 20px;
    width: 80%;
    @media screen and (min-width: 960px) {
      bottom: 50px;
      left: 15%;
    }
  }
  .tytul {
    z-index: 10;
    display: inline-block;
    display: none;
    height: auto;
    width: max-content;
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 0.5rem;
    border: 1px solid white;
    border-radius: 0.5rem;
    color: white;
    background-color: ${style.kolory.tayamaZielony};
    .action {
      transition: 0.3s;
      margin-left: 0.5rem;
      vertical-align: middle;
    }
    :hover {
      .action {
        margin-left: 1rem;
      }
    }
    a {
      color: white;
    }
    @media screen and (min-width: 960px) {
      display: none;
    }
  }
  .dosklepu {
    z-index: 10;
    display: inline-block;
    height: auto;
    width: max-content;
    color: white;
    position: absolute;
    top: -40px;
    left: 0;
    padding: 0.5rem;
    border: 1px solid white;
    border-radius: 0.5rem;
    background-color: ${style.kolory.tayamaZielony};
    .action {
      transition: 0.3s;
      margin-left: 0.5rem;
      vertical-align: middle;
    }
    :hover {
      .action {
        margin-left: 1rem;
      }
    }
    a {
      color: white;
    }
    @media screen and (min-width: 960px) {
      top: -70px;
      padding: 1rem;
      font-size: larger;
    }
  }
  .dosklepudol {
    z-index: 10;
    display: inline-block;
    height: auto;
    width: max-content;
    color: white;
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0.5rem;
    border: 1px solid white;
    border-radius: 0.5rem;
    background-color: ${style.kolory.tayamaZielony};
    .action {
      transition: 0.3s;
      margin-left: 0.5rem;
      vertical-align: middle;
    }
    :hover {
      .action {
        margin-left: 1rem;
      }
    }
    a {
      color: white;
    }
    @media screen and (min-width: 960px) {
      padding: 1rem;
      font-size: larger;
    }
  }
  .opis {
    z-index: 300;
    display: block;
    color: black;
    background-color: white;
    padding: 5px;
    width: 100%;
    left: 10px;
    top: -20px;
    p {
      margin: 0;
    }
  }
`
const Oferta = props => {
  let Storyblok = new StoryblokClient({
    accessToken: "nd85JvuayxXT5UlEnROrQgtt",
  })
  const [lp, setLp] = useState(0)
  const Dzial = x => {
    function ustaw () {
      setLp(x)
    }

    return (
      <div
        role='button'
        tabIndex={x}
        onClick={ustaw}
        onKeyDown={ustaw}
        onFocus={ustaw}
        onMouseOver={ustaw}
        className={lp === x ? "oferta aktualne" : "oferta nieaktualne"}
      >
        <div className='basic-card'>
          <picture>
            <img
              className='basic-card-image'
              src={resize(props.blok.items[x].img, "200x0/filters:quality(85)")}
              alt={props.blok.items[x].tytul}
            />
          </picture>
          <div className='basic-card-content'>
            <h6>{props.blok.items[x].tytul}</h6>
          </div>
        </div>
      </div>
    )
  }

  return (
    <SbEditable content={props.blok}>
      <Wypelniacz className='pudelko'>
        <picture>
          <img
            className='focia'
            src={resize(props.blok.items[lp].img, "1000x0/filters:quality(85)")}
            alt={props.blok.items[lp].tytul}
          />
        </picture>
        <div className='tytul'>
          <a href={props.blok.items[lp].link}>
            {props.blok.items[lp].tytul}
            <FaAngleRight className='action'></FaAngleRight>
          </a>
        </div>
        <div className='miniaturki'>
          {Dzial(0)}
          {Dzial(1)}
          {Dzial(2)}
          {Dzial(3)}
          {Dzial(4)}
        </div>
        <div
          className='opis'
          dangerouslySetInnerHTML={{
            __html: `${Storyblok.richTextResolver.render(
              props.blok.items[lp].info
            )}`,
          }}
        ></div>
        <button className='dosklepudol'>
          <a href={props.blok.items[lp].link}>
            {props.blok.items[lp].linktext}
            <FaAngleRight className='action'></FaAngleRight>
          </a>
        </button>
      </Wypelniacz>
    </SbEditable>
  )
}

export default Oferta
