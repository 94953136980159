import React from "react"
import SbEditable from "storyblok-react"
import { IconContext } from "react-icons"
import Ikony from "./Ikony"

const stylIkony = {
  display: "block",
  height: "3rem",
  width: "3rem",
  margin: "auto",
  color: "black",
}
const Ikona = props => {
  const Ikonka = Ikony.find(i => i.name === props.blok.typ)
  stylIkony.height = props.blok.wielkosc
  stylIkony.width = props.blok.wielkosc
  stylIkony.color = props.blok.kolor.color
  return (
    <SbEditable content={props.blok}>
      <IconContext.Provider value={{ style: stylIkony }}>
        <div>
          <Ikonka></Ikonka>
        </div>
      </IconContext.Provider>
    </SbEditable>
  )
}

export default Ikona
