import React from "react"
import styled from "styled-components"
import Slider from "./Slider"
import SbEditable from "storyblok-react"

const Wrapper = styled.section`
  position: relative;
  display: block;
`
const Baner = props => {
  return (
    <SbEditable content={props.blok}>
      <Wrapper id='Baner'>
        <Slider {...props}></Slider>
        {/* <SlickSlider {...props}></SlickSlider> */}
      </Wrapper>
    </SbEditable>
  )
}

export default Baner
