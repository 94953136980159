import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { resize } from "./helpers/ImageServices"
// import StoryblokClient from "storyblok-js-client"

const WrapDiv = styled.div`
  button.slick-arrow.slick-next {
    right: 0px;
    z-index: 10;
  }
  button.slick-arrow.slick-prev {
    left: 0px;
    z-index: 10;
  }
  .kropki {
    bottom: 10px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
`
// const Wrapper = styled.div`
//   position: absolute;
//   left: 0px;
//   top: 0px;
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   width: 100%;
//   margin: 0 auto;
//   background-color: rgba(0, 0, 0, 0);
//   text-align: center;
//   color: white;
//   font-weight: bold;
//   z-index: 1000;
//   a {
//     color: white;
//     font-weight: bold;
//     font-size: larger;
//     margin-top: 10%;
//     padding: 10px 30px;
//     background-color: ${style.kolory.tayamaZielony};
//     border-radius: 0.5rem;
//   }
//   h2,
//   h3 {
//     margin: 0 36px;
//     padding: 1% 3%;
//     backdrop-filter: blur(3px);
//     background-color: #77777770;
//     border-radius: 0.5rem;
//   }
// `
const SlickSlider = props => {
  // let Storyblok = new StoryblokClient({
  //   accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  // })

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    dotsClass: `slick-dots kropki`,
  }

  return (
    <WrapDiv>
      <Slider {...settings}>
        {props.blok.items.map((item, __uid) => {
          return (
            <div key={__uid}>
              <div style={{ position: "relative" }}>
                <a href={item.link}>
                  <img
                    src={resize(item.img, "300x400/filters:quality(85)")}
                    alt=''
                  ></img>
                </a>
                {/* <Wrapper>
                  <div
                    style={{ background: "transparent" }}
                    dangerouslySetInnerHTML={{
                      __html: `${Storyblok.richTextResolver.render(item.info)}`,
                    }}
                  ></div>
                  <a href={item.link}>{item.linktext}</a>
                </Wrapper> */}
              </div>
            </div>
          )
        })}
      </Slider>
    </WrapDiv>
  )
}
export default SlickSlider
