import React from "react"
import ReactMarkdown from "react-markdown"
import SbEditable from "storyblok-react"

const TextMD = props => {
  const Heading = `h${props.blok.title_size}`
  return (
    <SbEditable content={props.blok}>
      <div>
        <Heading
          style={{
            textAlign: props.blok.title_align,
            color: props.blok.title_color.color,
          }}
        >
          {props.blok.title}
        </Heading>
        <ReactMarkdown source={props.blok.content} />
      </div>
    </SbEditable>
  )
}

export default TextMD
