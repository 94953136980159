import Page from "./page"
import ComponentNotFound from "./component_not_found"
import Header from "./Header"
import Kontener from "./Kontener"
import Wiersz from "./Wiersz"
import Kolumna from "./Kolumna"
import Separator from "./Separator"
import Obrazek from "./Obrazek"
import LinkDiv from "./LinkDiv"
import TextMD from "./TextMD"
import Navbar from "./Navbar"
import Oferta from "./Oferta"
import Jedenkontakt from "./Jedenkontakt"
import Footer from "./Footer"
import Baner from "./Baner"
import Plik from "./Plik"
import Opis from "./Opis"
import Ikona from "./Ikona"
import Mapa from "./Mapa"
import GrupaKontaktow from "./GrupaKontaktow"
import ContactForm from "./ContactForm"
import Cookie from "./Cookie"
import Newsletter from "./Newsletter"
import ZapisPopUp from "./ZapisPopUp"
import Grid from "./Grid"
import Flex from "./Flex"
import SlickSlider from "./SlickSlider"
import Mailingi from "./Mailingi"
import Pobieralnia from "./Pobieralnia"
import ButtonIco from "./ButtonIco"

const ComponentList = {
  page: Page,
  navbar: Navbar,
  oferta: Oferta,
  jedenkontakt: Jedenkontakt,
  footer: Footer,
  baner: Baner,
  plik: Plik,
  kontener: Kontener,
  separator: Separator,
  obrazek: Obrazek,
  wiersz: Wiersz,
  kolumna: Kolumna,
  opis: Opis,
  ikona: Ikona,
  mapa: Mapa,
  header: Header,
  contactForm: ContactForm,
  cookie: Cookie,
  newsletter: Newsletter,
  grupakontaktow: GrupaKontaktow,
  zapisPopUp: ZapisPopUp,
  grid: Grid,
  flex: Flex,
  slickSlider: SlickSlider,
  mailingi: Mailingi,
  linkdiv: LinkDiv,
  textmd: TextMD,
  pobieralnia: Pobieralnia,
  buttonIco: ButtonIco,
}

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
