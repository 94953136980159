import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import { IconContext } from "react-icons"
import Ikony from "./Ikony"
import { style } from "../utils/Index"

const Blok = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  a {
    display: flex;
    color: black;
  }
`
const Down = styled.button`
  cursor: pointer;
  margin-left: 1rem;
  transition: 0.3s;
  :focus {
    outline: none;
  }
  .ikona {
    height: 1.1rem;
    width: 1.1rem;
    color: black;
    display: block;
    margin: 0 0;
    :hover {
      color: ${style.kolory.tayamaZielony};
    }
  }
`

const Plik = props => {
  console.log("plik", props)
  const Ikona = Ikony.find(i => i.displayName === "MdFileDownload")
  return (
    <SbEditable content={props.blok}>
      <Blok style={{ backgroundColor: props.parzyste ? "#eee" : "white" }}>
        {props.blok.tytul}
        <a href={props.blok.link} target='_blank' rel='noreferrer'>
          <Down type='button'>
            <IconContext.Provider value={{ className: "ikona" }}>
              <Ikona></Ikona>
            </IconContext.Provider>
          </Down>
        </a>
      </Blok>
    </SbEditable>
  )
}

export default Plik
