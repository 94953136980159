import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import { resize } from "./helpers/ImageServices"

const Wypelniacz = styled.div`
  position: relative;
  display: block;
  }
`
const Obrazek = props => {
  return (
    <SbEditable content={props.blok}>
      <Wypelniacz>
        <img

          src={resize(
            props.blok.img,
            props.blok.max_szer + "x" + props.blok.max_wys + "/filters:quality(85)"
          )}
          alt={props.blok.alt}
        ></img>
      </Wypelniacz>
    </SbEditable>
  )
}

export default Obrazek
