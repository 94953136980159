import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import StoryblokClient from "storyblok-js-client"

const Wypelniacz = styled.div`
  display: inline-block;
`

const Opis = props => {
  let Storyblok = new StoryblokClient({
    accessToken: "nd85JvuayxXT5UlEnROrQgtt",
  })

  var x = Storyblok.richTextResolver.render(props.blok.data)
  return (
    <SbEditable content={props.blok}>
      <Wypelniacz
        dangerouslySetInnerHTML={{
          __html: `${x}`,
        }}
      ></Wypelniacz>
    </SbEditable>
  )
}

export default Opis
