import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Components from "./components.js"

const Wypelniacz = styled.div`
  position: relative;
  display: ${props => props.display};
`

const Wiersz = props => {
  return (
    <SbEditable content={props.blok}>
      <Wypelniacz
        display={props.blok.flex ? "flex" : "block"}
        className={props.blok.klasa}
      >
        {props.blok.kolumny &&
          props.blok.kolumny.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </Wypelniacz>
    </SbEditable>
  )
}

export default Wiersz
