import {
  MdTrain,
  MdDirectionsBus,
  MdTram,
  MdGroup,
  MdFileDownload,
} from "react-icons/md"
import {
  AiOutlineGlobal,
  AiOutlineFileZip,
  AiOutlineFilePdf,
} from "react-icons/ai"
import { FaHandsHelping, FaWindows } from "react-icons/fa"
import { TiCogOutline, TiDocumentText, TiNews } from "react-icons/ti"

const Ikony = [
  MdTrain,
  MdDirectionsBus,
  MdTram,
  MdGroup,
  AiOutlineGlobal,
  AiOutlineFileZip,
  FaHandsHelping,
  TiCogOutline,
  TiDocumentText,
  TiNews,
  FaWindows,
  AiOutlineFilePdf,
  MdFileDownload,
]

export default Ikony
