import React from "react"

const ZapisPopUp = props => {
  const style = {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
    zIndex: "999999",
    display: "blok",
    alignItems: "center",
  }
  return (
    <div id='fm-pu-container' style={style}>
      <iframe
        title='popup'
        id='fm-fc-f-ane6mpmlcz'
        src='https://forms.freshmail.io/f/hx8u94z7gp/ane6mpmlcz/index.html'
        frameborder='0'
        marginheight='0'
        marginwidth='0'
        width='100%'
        style={{ minHeight: "322px" }}
      ></iframe>
    </div>
  )
}

export default ZapisPopUp
