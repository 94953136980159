import React from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import SbEditable from "storyblok-react"
import axios from "axios"
import styled from "styled-components"
import { FaAngleRight } from "react-icons/fa"
import ReCAPTCHA from "react-google-recaptcha"
import { style } from "../utils/Index"

const MyStyledButton = styled.button`
  margin-top: 1rem;
  cursor: pointer;
  outline-style: none;
  display: block;
  height: auto;
  width: max-content;
  color: white;
  padding: 0.5rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  background-color: ${style.kolory.tayamaZielony};
  .action {
    transition: 0.3s;
    margin-left: 0.5rem;
    vertical-align: middle;
  }
  :hover {
    .action {
      margin-left: 1rem;
    }
  }
  a {
    color: white;
  }
  @media screen and (min-width: 960px) {
    padding: 1rem;
    font-size: larger;
  }
`
const MyStyledCaptcha = styled.div`
  margin-top: 1rem;
`
const MyStyledInput = styled.input`
  margin: 0;
  padding: 0.5em;
  border: 1px solid #eee;
`
const MyStyledTextarea = MyStyledInput.withComponent("textarea")
// const MyStyledLabel = styled.label`
//   &&&.zgoda {
//     display: inline;
//     vertical-align: middle;
//   }
// `
const StyledErrorMessage = styled.div`
  color: red;
  font-size: smaller;
  font-style: italic;
`
const recaptchaRef = React.createRef()

const ContactForm = props => {
  return (
    <SbEditable content={props.blok}>
      <Formik
        initialValues={{
          name: "",
          temat: "",
          email: "",
          message: "",
          zgoda: false,
          recaptcha: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Pole wymagane"),
          temat: Yup.string().required("Pole wymagane"),
          email: Yup.string()
            .email("Błędny adres email")
            .required("Pole wymagane"),
          message: Yup.string().required("Pole wymagane"),
          zgoda: Yup.bool().oneOf([true, false], "Musisz wyrazić zgodę"),
          recaptcha: Yup.string().required("Wymagana weryfikacja"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios
            .post("/.netlify/functions/send-email", JSON.stringify(values), {
              headers: { "Content-Type": "application/json" },
            })
            .then(response => {
              recaptchaRef.current.reset()
              resetForm()
            })
            .catch(error => {
              console.log("Blad:", error)
            })
          setSubmitting(false)
        }}
      >
        {formik => {
          return (
            <Form style={{ backgroundColor: "#e8e8e8", padding: "1rem" }}>
              <label htmlFor='name'>Twoje dane</label>
              <Field name='name' as={MyStyledInput} type='text' />
              <ErrorMessage name='name' component={StyledErrorMessage} />
              <label htmlFor='email'>Twój adres email</label>
              <Field name='email' as={MyStyledInput} type='email' />
              <ErrorMessage name='email' component={StyledErrorMessage} />
              <label htmlFor='temat'>Temat</label>
              <Field name='temat' as={MyStyledInput} type='text' />
              <ErrorMessage name='temat' component={StyledErrorMessage} />
              <label htmlFor='message'>Twoja wiadomość</label>
              <Field
                name='message'
                as={MyStyledTextarea}
                type='textarea'
                rows='6'
              />
              <ErrorMessage name='message' component={StyledErrorMessage} />
              {/* <Field name='zgoda' as={MyStyledInput} type='checkbox' />
              <MyStyledLabel htmlFor='zgoda' className='zgoda'>
                Wyrażam zgodę na otrzymywanie drogą elektroniczną na wskazany
                przeze mnie adres email informacji handlowej w rozumieniu art.
                10 ust. 1 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług
                drogą elektroniczną od Tayama sp. z o.o. sp.k.
              </MyStyledLabel>
              <ErrorMessage name='zgoda' component={StyledErrorMessage} /> */}
              {/* <label htmlFor='recaptcha'></label> */}
              <MyStyledCaptcha>
                <ReCAPTCHA
                  sitekey='6Ld_030UAAAAACjSLav4SbwE7U4QSaKnbJRqv-Xw'
                  ref={recaptchaRef}
                  render='explicit'
                  onChange={value => {
                    if (value !== null) {
                      axios
                        .post("/.netlify/functions/recaptcha", value)
                        .then(response => {
                          formik.setFieldValue("recaptcha", value)
                        })
                        .catch(error => {
                          formik.setFieldValue("recaptcha", "")
                          console.log("Blad:", error)
                        })
                    }
                  }}
                ></ReCAPTCHA>
              </MyStyledCaptcha>
              <ErrorMessage name='recaptcha' component={StyledErrorMessage} />
              <MyStyledButton type='submit'>
                Wyślij
                <FaAngleRight className='action'></FaAngleRight>
              </MyStyledButton>
            </Form>
          )
        }}
      </Formik>
    </SbEditable>
  )
}

export default ContactForm
