import React from "react"
import CookieConsent from "react-cookie-consent"
const Cookie = props => {
  const styl = {
    background: "#f2f5f5",
    color: "#384a56",
    top: props.blok.top,
    width: "90%",
    left: "5%",
    textAlign: "left",
  }
  const buttonStyle = {
    background: "#006338",
    color: "white",
  }

  return (
    <CookieConsent
      debug={false}
      cookieName={props.blok.name}
      location='none'
      buttonText={props.blok.button}
      style={styl}
      buttonStyle={buttonStyle}
      expires={parseInt(props.blok.timeout)}
    >
      {props.blok.tresc}
    </CookieConsent>
  )
}
export default Cookie
