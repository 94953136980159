import React from "react"
import SbEditable from "storyblok-react"
import Components from "./components.js"

const Footer = props => {
  return (
    <SbEditable content={props.blok}>
      <footer id={props.blok.id} className={props.blok.klasa}>
        {props.blok.data &&
          props.blok.data.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </footer>
    </SbEditable>
  )
}

export default Footer
