import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"

const Wypelniacz = styled.hr`
  margin: 0;
  height: ${props => props.wysokosc};
  border-top: ${props => props.ramka_top};
  border-bottom: ${props => props.ramka_bottom};
`
const Separator = props => {
  return (
    <SbEditable content={props.blok}>
      <Wypelniacz
        wysokosc={props.blok.wysokosc}
        ramka_top={props.blok.ramka_top}
        ramka_bottom={props.blok.ramka_bottom}
      ></Wypelniacz>
    </SbEditable>
  )
}

export default Separator
