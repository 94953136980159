import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import { MdPhone, MdSmartphone, MdEmail } from "react-icons/md"
import { style } from "../utils/Index"

const Blok = styled.div`
  border: 0px solid lightgray;
  border-left: 5px solid ${style.kolory.tayamaZielony};
  border-top: 1px solid lightgray;
  margin-bottom: 1rem;
  .grubo {
    font-weight: bold;
    background-color: transparent;
    padding: 4px 10px;
  }
  .cienko {
    color: black;
    padding: 0px 10px;
    a {
      color: black;
    }
  }
  .ikona {
    vertical-align: middle;
    color: ${style.kolory.tayamaSzary};
    display: inline;
    margin-right: 1rem;
  }
`
const Jedenkontakt = props => {
  return (
    <SbEditable content={props.blok}>
      <Blok>
        <div className='grubo'>{props.blok.nazwa}</div>
        <div className='cienko'>
          <MdEmail className='ikona'></MdEmail>
          <a href={"mailto:" + props.blok.lp}>{props.blok.lp}</a>
        </div>
        {props.blok.tel2 === "" ? null : (
          <div className='cienko'>
            <MdSmartphone className='ikona'></MdSmartphone>
            <a href={"tel:" + props.blok.tel2}>{props.blok.tel2}</a>
          </div>
        )}
        {props.blok.tel1 === "" ? null : (
          <div className='cienko'>
            <MdPhone className='ikona'></MdPhone>
            <a href={"tel:" + props.blok.tel1}>{props.blok.tel1}</a>
          </div>
        )}
      </Blok>
    </SbEditable>
  )
}

export default Jedenkontakt
