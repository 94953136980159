import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import { IconContext } from "react-icons"
import Ikony from "./Ikony"
import { style } from "../utils/Index"

const Wrapper = styled.button`
  cursor: pointer;
  margin-right: 0.5rem;
  border-radius: 0.3rem;
  background-color: #eee;
  padding: 0.3rem;
  font-size: small;
  border: 1px solid #aaa;
  transition: 0.3s;
  :focus {
    outline: none;
    color: white;
    border-radius: 0.5rem;
    border-color: ${style.kolory.tayamaZielony};
    background-color: ${style.kolory.tayamaZielony};
    > .ikona {
      color: white;
    }
  }
  .ikona {
    height: 2rem;
    width: 2rem;
    color: ${props => props.blok.kolor.color};
    display: block;
    margin: auto;
  }
  @media screen and (min-width: 40em) {
    padding: 0.5rem 1rem;
    font-size: medium;
    .ikona {
      height: ${props => props.blok.wielkosc};
      width: ${props => props.blok.wielkosc};
    }
  }
  @media screen and (min-width: 64em) {
    padding: 0.5rem 2rem;
  }
  :hover {
    color: white;
    background: ${style.kolory.tayamaZielony};
    border-color: ${style.kolory.tayamaZielony};
    > .ikona {
      color: white;
    }
  }
`

const ButtonIco = props => {
  const Ikona = Ikony.find(i => i.name === props.blok.typ)
  return (
    <SbEditable content={props.blok}>
      <Wrapper
        type='button'
        blok={props.blok}
        onClick={() => props.funkcja(`${props.blok.info}`)}
      >
        <IconContext.Provider value={{ className: "ikona" }}>
          <Ikona></Ikona>
          {props.blok.info}
        </IconContext.Provider>
      </Wrapper>
    </SbEditable>
  )
}

export default ButtonIco
