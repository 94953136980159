import React from "react"
import SbEditable from "storyblok-react"
import Components from "./components.js"
import styled from "styled-components"
import { Element } from "react-scroll"

const Wrapper = styled.section`
  position: relative;
  display: block;
`
const Kontener = props => {
  return (
    <SbEditable content={props.blok}>
      <Wrapper>
        <Element
          id={props.blok.id}
          name={props.blok.id}
          className={props.blok.klasa}
          style={{
            background: props.blok.kolor.color,
          }}
        >
          {props.blok.data &&
            props.blok.data.map(blok =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
        </Element>
      </Wrapper>
    </SbEditable>
  )
}

export default Kontener
