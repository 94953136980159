import React from "react"
import SbEditable from "storyblok-react"

const Mapa = props => {
  return (
    <SbEditable content={props.blok}>
      <iframe
        title='mapa'
        width='100%'
        height='400px'
        frameBorder='1'
        src='https://www.google.com/maps/embed/v1/place?q=Tayama%20sp.%20z%20o.o.%20sp.%20k.&key=AIzaSyB3ZcaTXstAbdX9DDS1yzpbyqIikFBUMdY&zoom=13'
      ></iframe>
    </SbEditable>
  )
}

export default Mapa
