import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Components from "./components.js"

const Wypelniacz = styled.div`
  position: relative;
  display: block;
`

const Kolumna = props => {
  return (
    <SbEditable content={props.blok}>
      <Wypelniacz className={props.blok.klasa}>
        {props.blok.data &&
          props.blok.data.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </Wypelniacz>
    </SbEditable>
  )
}

export default Kolumna
