import React from "react"
import styled from "styled-components"
import { IconContext } from "react-icons"
import Ikony from "./Ikony"
import { style } from "../utils/Index"

const Blok = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  a {
    display: flex;
    color: black;
  }
`
const Down = styled.button`
  cursor: pointer;
  margin-left: 1rem;
  transition: 0.3s;
  :focus {
    outline: none;
  }
  .ikona {
    height: 1.1rem;
    width: 1.1rem;
    color: black;
    display: block;
    margin: 0 0;
    :hover {
      color: ${style.kolory.tayamaZielony};
    }
  }
`
const PlikKZ = props => {
  // console.log("plikKZ", props)
  const Ikonka = Ikony.find(i => i.name === "MdFileDownload")
  return (
    <Blok style={{ backgroundColor: props.parzyste ? "#eee" : "white" }}>
      {props.tytul}
      <a href={props.link} target='_blank' rel='noreferrer'>
        <Down type='button'>
          <IconContext.Provider value={{ className: "ikona" }}>
            <Ikonka></Ikonka>
          </IconContext.Provider>
        </Down>
      </a>
    </Blok>
  )
}

export default PlikKZ
