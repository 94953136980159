import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Components from "./components.js"

const Blok = styled.div`
  background-color: transparent;
  .kontakty {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr;
    @media screen and (min-width: 990px) {
      grid-template-columns: 1fr 1fr;
    }
    .handlowcy {
      font-weight: bold;
    }
  }
`
const Jedenkontakt = props => {
  return (
    <SbEditable content={props.blok}>
      <Blok>
        <h4>{props.blok.info}</h4>
        <div className='kontakty'>
          {props.blok.osoby.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        </div>
      </Blok>
    </SbEditable>
  )
}

export default Jedenkontakt
