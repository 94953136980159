import React from "react"
import styled from "styled-components"

const Wypelniacz = styled.section`
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar nagl"
    "sidebar content"
    "footer footer";
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;

  .towary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1vh;
  }
  .towar {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .cena {
    display: flex;
    flex: 0 1 auto;
  }
  .nazwa {
    flex: 1 0 auto;
  }
  header,
  footer {
    border-radius: 5px;
    padding: 10px;
    background-color: rgb(207, 232, 220);
    border: 2px solid rgb(79, 185, 227);
  }
  aside {
    border-right: 1px solid #999;
  }
  nagl {
    grid-area: nagl;
  }
  header {
    grid-area: header;
  }
  article {
    grid-area: content;
  }
  aside {
    grid-area: sidebar;
  }
  footer {
    grid-area: footer;
  }
`

const Grid = props => {
  return (
    <Wypelniacz>
      <header>Tu będzie nagłówek</header>
      <nagl>
        <p>Tu będzie siatka towarów</p>
        <picture>
          <img src='https://picsum.photos/900/400' alt='' />
        </picture>
      </nagl>
      <article>
        <div className='towary'>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img
                  src='https://sklep.tayama.pl/img/medium/11127/dysk-seagate-2tb-sata-600-64mb-cache-skyhawk-3-5.jpg'
                  alt=''
                />
              </picture>
              <figcaption className='nazwa'>
                Dysk Seagate 2TB SATA/600 64MB SkyHawk HDD 3.5" 5900 RPM{" "}
              </figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img
                  src='https://sklep.tayama.pl/img/medium/12470/hiwatch-kamera-ip-kopulkowa-2-0-mpix-fl-2-8mm-ir.jpg'
                  alt=''
                />
              </picture>
              <figcaption className='nazwa'>
                HiWatch Kamera IP kopułkowa 2.0 Mpix, FL 2.8mm, IR 30m, biała
              </figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img src='https://picsum.photos/300/200' alt='' />
              </picture>
              <figcaption className='nazwa'>
                tu bedzie nazwa dssss kelkjhjihu kjhksbkbjskf knjkbk
                jbsklblabflk kljsfkjabfdska
              </figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img src='https://picsum.photos/300/200' alt='' />
              </picture>
              <figcaption className='nazwa'>
                tu bedzie nazwa dssss kelkjhjihu kjhksbkbjskf
              </figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img src='https://picsum.photos/300/200' alt='' />
              </picture>
              <figcaption className='nazwa'>tu bedzie nazwa</figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img src='https://picsum.photos/300/200' alt='' />
              </picture>
              <figcaption className='nazwa'>
                tu bedzie nazwa dssss kelkjhjihu kjhksbkbjskf knjkbk
                jbsklblabflk kljsfkjabfdska
              </figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img src='https://picsum.photos/300/200' alt='' />
              </picture>
              <figcaption className='nazwa'>
                tu bedzie nazwa dssss kelkjhjihu kjhksbkbjskf
              </figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img src='https://picsum.photos/300/200' alt='' />
              </picture>
              <figcaption className='nazwa'>tu bedzie nazwa</figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
          <div className='towar'>
            <div className='nazwa'>
              <picture>
                <img src='https://picsum.photos/300/200' alt='' />
              </picture>
              <figcaption className='nazwa'>
                tu bedzie nazwa dssss kelkjhjihu kjhksbkbjskf knjkbk
                jbsklblabflk kljsfkjabfdska
              </figcaption>
            </div>
            <div className='cena'>1000,00 zł</div>
          </div>
        </div>
      </article>
      <aside>
        <p>Tu będzie menu boczne</p>
      </aside>
      <footer>Tu będzie stopka</footer>
    </Wypelniacz>
  )
}

export default Grid
