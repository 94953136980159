import React from "react"
import styled from "styled-components"
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider"
import StoryblokClient from "storyblok-js-client"
import { resize } from "./helpers/ImageServices"
import { style } from "../utils/Index"

const Wypelniacz = styled.section`
  padding-top: calc(100% * 960 / 1920);
  @media screen and (orientation: portrait) {
    padding-top: 80%;
    padding-top: calc(100% * 960 / 1920);
  }
  background: lightgray;
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  .hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .rm-hero-slider {
    width: 100%;
    height: 100%;
  }
  .slide-nav-button {
    @media screen and (orientation: portrait) {
      display: none;
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  color: white;
  font-weight: normal;
  z-index: 30;
  a {
    color: white;
    font-weight: bold;
    font-size: larger;
    margin-top: 10%;
    padding: 10px 30px;
    background-color: ${style.kolory.tayamaZielony};
    border-radius: 0.5rem;
  }
  .h2h3 {
    margin: 0 36px;
    padding: 1% 3%;
    backdrop-filter: blur(3px);
    background-color: #77777770;
    border-radius: 0.5rem;
    @media screen and (orientation: portrait) {
    margin: 0 36px;
    padding: 1% 3%;
    }
  }
`
const Slider = props => {
  let Storyblok = new StoryblokClient({
    accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  })
  return (
    <Wypelniacz>
      <div className='hero'>
        <HeroSlider
          settings={{
            slidingDuration: 300,
            slidingDelay: 300,
            autoplayDuration: 5000,
            shouldAutoplay: true,
            shouldDisplayButtons: true,
          }}
        >
          <OverlayContainer
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0)",
              textAlign: "center",
            }}
          ></OverlayContainer>
          {props.blok.items.map((item, __uid) => {
            return (
              <Slide
                key={__uid}
                background={{
                  backgroundImage: resize(
                    item.img,
                    "1280x640/filters:quality(85)"
                  ),
                  backgroundAttachment: "100%",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "norepeat",
                }}
              >
                  {item.link === "" ? 
                <Wrapper>
                  {item.info.content[0].content === undefined ? null : (
                  <div
                  className="h2h3"
                    dangerouslySetInnerHTML={{
                      __html: `${Storyblok.richTextResolver.render(item.info)}`,
                    }}
                  ></div>
                  )}
                </Wrapper>
                 : 
                <a href={item.link}>
                <Wrapper>
                  {item.info.content[0].content === undefined ? null : (
                  <div
                  className="h2h3"
                    dangerouslySetInnerHTML={{
                      __html: `${Storyblok.richTextResolver.render(item.info)}`,
                    }}
                  ></div>
                  )}
                  {item.linktext === "" ? null : (
                  <a href={item.link}>{item.linktext}</a>
                  )}
                </Wrapper>
                </a>}
              </Slide>
            )
          })}
          <Nav />
        </HeroSlider>
      </div>
    </Wypelniacz>
  )
}
export default Slider
