import React, { useState } from "react"
import styled from "styled-components"
import { Link, animateScroll as scroll } from "react-scroll"
import { FaAlignRight } from "react-icons/fa"
import logoimg from "../../static/nowe_logo.png"
import foto from "../../static/foto.jpg"
import allegro from "../../static/allegro.svg"
import styles from "./navbar.module.scss"
import { style } from "../utils/Index"

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: block;
  width: 100%;
  color: white;
  border-bottom: 1px solid white;
  background-color: ${style.kolory.tayamaSzary};
  .logoTAYAMA {
    max-height: 4vh;
  }
  .ikona {
    color: white;
    display: inline;
    align-content: center;
  }
  .ukryj {
    display: none;
  }
  .pokaz {
    display: inline-block;
  }
  .podmenu {
    float: none;
    @media screen and (min-width: 1080px) {
      float: left;
    }
  }
  .reklama {
    float: right;
    padding: 10px;
    width: 420px;
    display: none;
    @media screen and (min-width: 1080px) {
      display: block;
    }
  }
  .reklama img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .allegro {
    max-height: 20px;
  }
`

const Navbar = () => {
  const [isOpen, setNav] = useState(false)
  const taggleNav = () => {
    setNav(isOpen => !isOpen)
  }
  const kasujNav = () => {
    setNav(false)
  }

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  const LogoWrapper = styled.div`
    .logoButton {
      cursor: pointer;
      outline-style: none;
    }
    .logoImage {
      max-height: 40px;
    }
    .mobileButton {
      max-height: 57px;
      background: transparent;
      border: none;
      outline: none;
      padding: 1rem 1.25rem;
      :hover {
        cursor: pointer;
      }
      @media screen and (min-width: 1080px) {
        display: none;
      }
      .icon {
        color: white;
        font-size: 1.5rem;
      }
    }
  `
  const Logo = () => {
    return (
      <LogoWrapper
        className={
          isOpen
            ? `${styles.navHeader} ${styles.navLinia}`
            : `${styles.navHeader}`
        }
      >
        <button type='button' className='logoButton' onClick={scrollToTop}>
          <img src={logoimg} className='logoImage' alt='logo Tayama'></img>
        </button>
        <button
          type='button'
          aria-label='mobile menu taggle'
          className='mobileButton'
          onClick={taggleNav}
        >
          <FaAlignRight className='icon' />
        </button>
      </LogoWrapper>
    )
  }
  const Drop = () => {
    return (
      <div className={styles.dropdownContent}>
        <div className={"podmenu"}>
          <a href='https://sklep.tayama.pl/oferta-handlowa/telewizja-przemyslowa,39,11382'>
            Telewizja przemysłowa
          </a>
          <a href='https://sklep.tayama.pl/oferta-handlowa/oswietlenie-led,39,11383'>
            Oświetlenie LED
          </a>
          <a href='https://sklep.tayama.pl/oferta-handlowa/kontrola-dostepu,39,11385'>
            Kontrola dostępu
          </a>
          <a href='https://sklep.tayama.pl/oferta-handlowa/osprzet-elektroinstalacyjny,39,11386'>
            Osprzęt elektroinstalacyjny
          </a>
          <a href='https://sklep.tayama.pl/oferta-handlowa/zasilanie,39,11387'>
            Zasilanie
          </a>
        </div>
        <div className={"reklama"}>
          <img src={foto} alt='tayama'></img>
        </div>
      </div>
    )
  }
  const ScrollLink = props => {
    return (
      <Link
        activeClass='active'
        to={props.gdzie}
        spy={true}
        smooth={true}
        offset={-57}
        duration={500}
        onClick={kasujNav}
        isDynamic={true}
      >
        {props.info}
      </Link>
    )
  }

  return (
    <Wrapper>
      <div className={styles.navCenter}>
        <Logo></Logo>
        <ul
          className={
            isOpen
              ? `${styles.navLinks}`
              : `${styles.navLinks} ${styles.showNav}`
          }
        ></ul>
        <ul
          className={
            isOpen
              ? `${styles.navLinks}`
              : `${styles.navLinks} ${styles.showNav}`
          }
        >
          <li>
            <a
              href='https://allegro.pl/uzytkownik/Tayama_Polska?bmatch=baseline-cl-eyesa2-dict43-uni-1-1-0205'
              onClick={taggleNav}
            >
              <img src={allegro} className='allegro' alt=''></img>
            </a>
          </li>
          <li className={styles.dropdown}>            
            <a href='https://sklep.tayama.pl'>eSklep</a>
            <Drop></Drop>
          </li>
          <ScrollLink gdzie='Oferta' info='Oferta handlowa' />
          <ScrollLink gdzie='Wynajem' info='Wynajem powierzchni' />
          <ScrollLink gdzie='Onas' info='O nas' />
          <ScrollLink gdzie='Kontakt' info='Kontakt' />
          <ScrollLink gdzie='Download' info='Do pobrania' />
        </ul>
      </div>
    </Wrapper>
  )
}

export default Navbar
